<template>
  <div>
    <v-row data-cy="support-ticket-modal-window-info">
      <v-col
        cols="12"
        md="6"
      >
        <v-row dense>
          <v-col data-cy="support-ticket-state-and-priority">
            <div class="mb-3">
              <div class="overline">
                <span v-t="'support.state'" />
              </div>
              <div>
                <div>{{ ticket.state }}</div>
              </div>
            </div>
            <div class="mb-3">
              <div class="overline">
                <span v-t="'support.priority'" />
              </div>
              <div>
                <div>{{ ticket.priority }}</div>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="mb-3">
              <div class="overline">
                <span v-t="'support.created'" />
              </div>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ ticket.createDateTime | formatDateTime }}
                  </span>
                </template>
                <span>{{ ticket.createDateTime | formatDateTimeWithTimezone }}</span>
              </v-tooltip>
            </div>
            <div class="mb-3">
              <div class="overline">
                <span v-t="'support.lastUpdate'" />
              </div>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ ticket.lastUpdateDateTime | formatDateTime }}
                  </span>
                </template>
                <span>{{ ticket.lastUpdateDateTime | formatDateTimeWithTimezone }}</span>
              </v-tooltip>
            </div>
          </v-col>
          <v-col
            cols="12"
          >
            <v-row dense>
              <v-col v-if="ticket.state !== TICKET_STATE_DONE">
                <v-btn
                  rounded
                  depressed
                  color="primary"
                  data-cy="support-close-ticket-button"
                  @click="onCloseTicket"
                >
                  {{ $t('close') }}
                </v-btn>
              </v-col>
              <v-col>
                <v-btn
                  v-if="!isPriorityMaximal"
                  depressed
                  outlined
                  rounded
                  color="primary"
                  data-cy="support-escalate-ticket-button"
                  @click="escalateTicket(ticket)"
                >
                  {{ $t('support.escalate') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="6"
        data-cy="support-ticket-description"
      >
        <div class="mb-3">
          <div class="overline">
            <span v-t="'description'" />
          </div>
          <div>
            <!-- eslint-disable vue/no-v-html -->
            <span
              class="description"
              v-html="sanitizeHtml(ticket.description)"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </v-col>
    </v-row>
    <material-support-ticket-attachments
      v-if="ticket.attachments && ticket.attachments.length > 0"
      :attachments="ticket.attachments"
      :loading-id="`ticket-${ticket.id}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DOMPurify from 'dompurify'
import { PRIORITY_URGENT } from '@/constants/support'
import { TICKET_STATE_DONE } from '@/constants/app'

export default {
  data () {
    return { TICKET_STATE_DONE }
  },
  computed: {
    ...mapState('support', ['showApiErrors']),
    ...mapGetters('support', { ticketGetter: 'ticket' }),
    isPriorityMaximal () {
      return this.ticket.priority === PRIORITY_URGENT
    },
    clientId () {
      return this.$route.params.clientId
    },
    ticketId () {
      return this.$route.params.ticketId
    },
    ticket () {
      return this.ticketGetter(this.clientId, this.ticketId)
    }
  },
  methods: {
    ...mapActions('support', ['closeTicket', 'escalateTicket']),
    async onCloseTicket () {
      await this.closeTicket(this.ticket)
      if (!this.showApiErrors) {
        this.$router.go(-1)
      }
    },
    sanitizeHtml (html) {
      return DOMPurify.sanitize(html)
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .v-expansion-panel-header__icon {
    flex: 1 1 100%;
  }

  ::v-deep .supportInfo__attachment {
    padding-bottom: $spacer * 2;
  }

  ::v-deep .supportInfo__attachmentHeader,
  ::v-deep .v-expansion-panel--active>.v-expansion-panel-header {
    padding: 0;
    min-height: auto;
  }
</style>
